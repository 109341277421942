<template>
    <div class="Share full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-8 flex-grow-1 bg-white ">
                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="tableHeaders"
                    :items="pathlist"
                >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item) in items" :key="item.id" @click="editRow(item)">
                            <td><div class="bullet" :class="{'connected': item.connectionStatus}"></div></td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.path }}</td>
                            <td :class="{edit: checkUserRights('systemShareEdit'), show: !checkUserRights('systemShareEdit')}"></td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToSystem"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="new" @footerButtonClick="newShare" :class="{'disabled': !checkUserRights('systemShareEdit')}"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Share',
    components: {
        FooterButton,
    },
    props: {
    },
    data () {
        return {
        }
    },
    computed: {
        ...mapState([
            'pathlist'
        ]),
        tableHeaders(){
            return [
                { text: this.$t('general.status'), align: 'start', value: 'connectionStatus', sortable: false},
                { text: this.$t('general.name'), align: 'start', value: 'name' },
                { text: this.$t('system.share.path'), sortable: false },
                { text:"", sortable: false}
            ]
        },
    },
    methods: {
        goToSystem(){
            this.$router.push('system');
        },
        editRow(share){
            this.$store.commit('ChangeEditShare', share);
        },
        newShare(){
            let share = {
                id: null,
                type: '',
                name: '',
                path: '',
                username: '',
                password: '',
                domain: '',
                connectionStatus: false
            }
            this.$store.commit('ChangeEditShare', share);
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/share/pathlist', 'pathlist']);
    },
}
</script>
<style scoped lang="scss">
.bullet{
    display: flex;
    align-items: center;
    &::before{
        content: "";
        display: inline-block;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $disiRed;
    }
    &.connected::before{
        background-color: $disiIrisblue;
    }
}
</style>